<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

// Componnets
import Banner from '@/Components/Banner.vue';
import Steps from '@/Components/Steps/Steps.vue';
import BlogRecent from '@/Components/Blog/BlogRecent.vue';
import DownloadApp from '@/Components/DownloadApp/DownloadApp.vue';
import { Link, usePage } from '@inertiajs/vue3';
const locale = computed(() => usePage().props.locale);

// Layout
import PublicLayout from '@/Layouts/PublicLayout.vue';

// Partials
import Customers from './Partials/Customers.vue';

// Components
import ContentGradient from '@/Components/Content/ContentGradient.vue';
import CounterWrapper from '@/Components/Counter/CounterWrapper.vue';
import CtaBar from '@/Components/Cta/CtaBar.vue';
import CtaMockup from '@/Components/Cta/CtaMockup.vue';
import FormNewsletter from '@/Components/Form/FormNewsletter.vue';
import ContactForm from '@/Components/Forms/Contact.vue';
import LandingspageBanner from '@/Components/Landingspage/LandingspageBanner.vue';
import LandingspageUsps from '@/Components/Landingspage/LandingspageUsps.vue';
import ReviewRepeater from '@/Components/Review/ReviewRepeater.vue';
import SignUp from '@/Components/SignUp/SignUp.vue';
import PageBlockStep from '@/Components/Steps/PageBlockStep.vue';
import PublicPaginator from '@/Components/PublicPaginator.vue';
import JobOfferCard from '@/Components/JobOffers/JobOfferCard.vue';

const { t } = useI18n();

const props = defineProps({
    page: Object,
    jobOffers: Object,
    blogArticles: {
        type: Array,
        required: true,
    },
});

</script>

<template>
    <PublicLayout title='Flexable'>
        <Banner />
        <template v-for='block in page.blocks' :key='block.id'>
            <LandingspageBanner
                v-if="block.component_name == 'landingspage-banner'"
                :media='block.media'
                v-bind='block.content'
            />

            <LandingspageUsps
                v-if="block.component_name == 'landingspage-usps'"
                :media='block.media'
                v-bind='block.content'
            />

            <SignUp v-if="block.component_name == 'sign-up'" :media='block.media' v-bind='block.content' />

            <ContentGradient
                v-if="block.component_name == 'landingspage-gradient-text'"
                :media='block.media'
                v-bind='block.content'
            />

            <PageBlockStep
                v-if="block.component_name == 'steps'"
                :block='block'
            />

            <CtaMockup v-if="block.component_name == 'cta-mockup'" :media='block.media' v-bind='block.content' />

            <CounterWrapper v-if="block.component_name == 'counter'" :media='block.media' v-bind='block.content' />

            <FormNewsletter v-if="block.component_name == 'newsletter'" :media='block.media' v-bind='block.content' />

            <ReviewRepeater
                v-if="block.component_name == 'review-repeater'"
                :media='block.media'
                v-bind='block.content'
            />

            <LandingspageContent
                v-if="block.component_name == 'landingspage-content'"
                :media='block.media'
                v-bind='block.content'
            />

            <BlogRecent
                v-if="block.component_name == 'blog-recent'"
                :blog-articles='blogArticles'
                :media='block.media'
                v-bind='block.content'
            />

            <CtaBar v-if="block.component_name == 'cta-bar'" :media='block.media' v-bind='block.content' />

            <ContactForm v-if="block.component_name == 'contact-form'" :media='block.media' v-bind='block.content' />
        </template>

        <Customers />

        <div class='py-20 md:text-left bg-white' v-if="locale === 'nl'">
            <div class='container relative'>
                <h2 class='mb-15 text-2xl font-medium sm:text-3xl uppercase xl:text-5xl md:text-center text-blue'>
                   <span class='font-black'>
                       {{$t('Looking for more stability?')}}

                    </span>
                    <br>
                    <span class=''>

                        {{$t('View our current vacancies here!')}}

                     </span>


                </h2>

                <div v-if='jobOffers.length' class='grid grid-cols-1 md:grid-cols-2 gap-8'>
                    <JobOfferCard v-for='jobOffer in jobOffers' :key='jobOffer.id' :jobOffer='jobOffer'>
                    </JobOfferCard>
                </div>

                <div v-else class='my-20'>
                    <div class='text-3xl text-center font-bold text-blue'>
                        <span> {{ $t('No job offers found') }} </span>
                    </div>
                </div>


                <div class='flex justify-center'>
                    <Link :href="route('public.job_offers.index')" class='mb-15 text-2xl font-bold  uppercase md:text-center text-pink'>

                        {{$t('View All flexible vacancies')}}

                    </Link>
                </div>

            </div>



        </div>

        <!--        <BlogRecent :blog-articles="blogArticles" extraPaddingBottom />-->
    </PublicLayout>
</template>
